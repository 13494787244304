import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const SingleReview = ({
  imgSrc,
  title,
  description,
  reviewer,
  reviewer_designation,
}) => {
  const breakpoints = useBreakpoint();
  return (
    <>
      <div
        className="bg-cover w-full  h-full  pt-40 md:pt-40 pb-28 font-sans z-10"
        style={{
          backgroundImage: `${
            breakpoints.l
              ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/background_light_mobile.svg")`
              : `url("https://d2lxkizvrhu4im.cloudfront.net/images/background_light.svg")`
          }`,
        }}
      >
        <div className="flex flex-col justify-center text-center w-full mx-auto lg:w-10/12">
          <div className="text-4xl md:text-5xl font-bold w-4/5 md:w-2/3 text-center mx-auto mb-10 md:mb-20">
            {title}
          </div>
          <div>
            <div className="flex  md:flex-row flex-col justify-between   space-y-3 md:space-y-0 ">
              <div className=" w-1/3 mx-auto">
                <img
                  alt="article"
                  src={imgSrc}
                  className="  rounded-xl overflow-hidden m-0"
                  width={400}
                  height={400}
                />
              </div>
              <div className=" flex flex-col  pl-3 w-11/12 mx-auto  md:w-1/2  text-center md:text-left align-center justify-center space-y-12">
                <div
                  className=" text-grey-100 text-2xl md:text-3.4xl font-medium  "
                  style={{ fontWeight: "bold" }}
                >
                  {description}
                </div>

                <div className=" flex flex-col  text-lg my-5">
                  <div className=" font-semibold">{reviewer}</div>
                  <div className="font-normal">{reviewer_designation} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleReview;
