import React from "react";

import Mereos from "../what_is_Mereos/mereos";
import Exam from "../exam/exam";
import SingleReview from "../reviews/singleReview";
import Subscribe2 from "../subscribe/subscribe_2";
import FAQS from "../faqs/faqs";
import Review from "../reviews/reviews";
import Help from "../help/help";
import BannerWrapper from "../top_banner/banner_wrapper";
import * as classes from "../top_banner/top_banner.module.scss";
import { useTranslation } from 'react-i18next';

const PlatformAI = () => {
  const {t} = useTranslation()
  const ReviewsData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/profile.png",
      name: "Sabine Romeu",
      designation: "DRH Cours Legendre",
      reviewTitle: "Un seul avis compte: celui de nos clients",
      review:
        "Il devenait de plus en plus difficile de coordonner le recrutement de milliers d’enseignants en présentiel. Mereos nous a apporté la flexibilité qui nous manquait. Depuis que nous utilisons Mereos, notre taux de conversion est exceptionnel.",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/profile.png",
      name: "Sabine Romeu",
      designation: "DRH Cours Legendre",
      reviewTitle: "Un seul avis compte: celui de nos clients",
      review:
        "Il devenait de plus en plus difficile de coordonner le recrutement de milliers d’enseignants en présentiel. Mereos nous a apporté la flexibilité qui nous manquait. Depuis que nous utilisons Mereos, notre taux de conversion est exceptionnel.",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/profile.png",
      name: "Sabine Romeu",
      designation: "DRH Cours Legendre",
      reviewTitle: "Un seul avis compte: celui de nos clients",
      review: "Il devenait de plus en plus difficile de coordonner le recrutement de milliers d’enseignants en présentiel. Mereos nous a apporté la flexibilité qui nous manquait. Depuis que nous utilisons Mereos, notre taux de conversion est exceptionnel.",
    },
  ];

  const FaqsData = [
    {
      title: `${t('when_does_mereos_report_candidate_behavior')}`,
      description: `${t('mereos_reports_a_candidate_behavior_based')}`,
    },
    {
      title: `${t('does_mereos_use_facial_recognition')}`,
      description: `${t('no_in_accordance_with_cnil')}`,
    },
    {
      title: `${t('does_mereos_analyze_eye_movements')}`,
      description: `${t('no_mereos_does_not_monitor_candidates')}`,
    },
    {
      title: `${t('what_happens_if_a_candidate_looks_at_their')}`,
      description: `${t('subtle_movements_such_as_those_mentioned')}`,
    },
    {
      title: `${t('does_the_mereos_algorithm_make_decisions')}`,
      description: `${t('no_the_mereos_algorithm_only_provides')}`,
    },
  ];

  const exams = [
    {
      title: `${t('analysis_of_suspicious_behavior')}`,
      description: `${t('depending_on_the_parameters_chosen')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/girl_in_yellow_tShirt.png",
    },
    {
      title: `${t('fraud_risk_score')}`,
      description:
        `${t('each_candidate_is_assigned_a_score')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/AI_exams_2.png",
    },
    {
      title: `${t('human_review_of_sessions')}`,
      description:
        `${t('you_can_view_sessions_of_candidates')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/AI_exams_3.png",
    },
    {
      title: `${t('editing_fraud_reports')}`,
      description:
        `${t('in_the_event_of_proven_fraud')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/AI_exams_4.png",
    },
  ];

  return (
    <>
      <div className={`conatiner font-sans`}>
        <BannerWrapper>
          <div
            className={` flex flex-col md:flex-row  justify-center items-center h-full py-16  mx-auto ${classes.wrapper}`}
          >
            <div
              className={`flex flex-col lg:flex-row  justify-center items-center w-full `}
            >
              <div
                className={`w-full lg:w-6/12 md:mb-32 lg:mb-0 flex flex-col lg:items-end  p-0 h-full `}
              >
                <div
                  className={`flex flex-col w-full md:w-10/12   space-y-2 md:space-y-5 mx-auto md:mx-0 items-center lg:items-start  mb-10 md:mb-0`}
                >
                  <div
                    className={`text-xs md:text-base font-semibold text-center lg:text-left  opacity-80`}
                  >
                    {t('automated_monitoring')}
                  </div>
                  <div
                    className={`font-bold text-3xl md:text-6xl  w-11/12 text-center lg:text-left  md:w-10/12`}
                  >
                    <h1>{t('recruit_faster_smarter')}</h1>
                  </div>
                  <div
                    className={` text-sm md:text-xl  font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0 w-11/12 md:w-1/2 lg:w-10/12`}
                  >
                    {t('with_mereos_artificial_intelligence_it_is_no_longer')}
                  </div>
                </div>
              </div>
              <div
                className={`w-9/12  lg:w-6/12 bg-cover  bg-no-repeat p-0 md:my-0 h-full mx-auto flex flex-col items-center md:items-start`}
              >
                <img
                  src={"https://d2lxkizvrhu4im.cloudfront.net/images/platform_top_banner_site_structure.svg"}
                  alt="img banner"
                  className=" m-0 p-0 w-9/12"
                  style={{ maxHeight: "550px", maxWidth: "750px" }}
                />
              </div>
            </div>
          </div>
        </BannerWrapper>

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          <div className="my-20">
            <Exam
              page={"AI"}
              title={t('how_does_our_artificial_intelligence_work')}
              Data={exams}
            />
          </div>
        </div>

        <SingleReview
          imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/numan.png"
          title={t('meet_our_experts')}
          description={t('we_have_analyzed_thousands_of_videos')}
          reviewer="Dr. Numan Khurshid"
          reviewer_designation="Computer Vision Engineer chez Mereos"
        />

        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          {/* <div className="my-20"><StripeFeatures/></div> */}

          <div className="my-32">
            {" "}
            <FAQS
              title={t('the_most_artificial_intelligence_works')}
              faqsType='FAQ'
              data={FaqsData}
            />
          </div>

          <div className="my-20">
            <div className="space-y-2 md:space-y-10">
              {" "}
              <div className="text-2xl md:text-5xl  text-center  font-bold md:w-1/2 mx-auto">
                {t('mereos_artificial_intelligence_in_action')}
              </div>
              <div
                className={`  font-medium text-sm md:text-xl  text-center mx-auto  my-4 text-gray-500  `}
              >
                {t('because_a_video_is_worth_more')} <br className="hidden lg:block" />{" "}
                {t('artificial_allows_you_to_secure_the')}
              </div>
            </div>
            <Mereos videoSrc="https://www.youtube.com/embed/2i9DDxw_hY8" />
          </div>
        </div>

        {/* <div>
          <Review Data={ReviewsData} />
        </div> */}
        <div className={`mx-auto w-11/12 lg:w-10/12 my-20`}>
          <Help />
        </div>
        <Subscribe2 />
      </div>
    </>
  );
};

export default PlatformAI;
