import * as React from "react"

import PlatformAIComponent from "../components/platform/platform_AI";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const PlatformAI = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={`${t('automated_monitoring')}`}
        description={t('automate_the_monitoring_of_your_candidates')}
      />
      <Layout>
        <PlatformAIComponent />
      </Layout>
    </>
  )
}

export default PlatformAI
